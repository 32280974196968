<template>
  <v-container>
    <div class="d-flex align-center flex-wrap mb-2" style="gap: 20px">
      <!-- <v-btn color="primary" @click.stop="items = []; getItems(loadpage)">
        <v-icon left>mdi-reload</v-icon>
        recargar
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn small :loading="exporting" color="secondary" @click.stop="exportarTodos">
        <v-icon left>mdi-microsoft-excel</v-icon>
        {{ $t('common.exportar') }}
      </v-btn>
    </div>
    <v-card>
      <div class="table-container">

        <app-table :search="search" :headers="headers" :items="items" item-key="ref" :loading="loading" show-select
          v-model="selected" :page.sync="page" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [pagesize],
          }" hide-default-footer checkbox-color="secondary" @click:row="(_, { item }) => (detailsId = item.ref)"
          @page-count="pageCount = $event">

          <div class="scroll-top" ref="scrollTop" @scroll="syncScroll">
          </div>

          <template v-slot:body.prepend="{ headers }">
            <table-filters :headers="headers" :items="items" v-model="inlineFilters" />
          </template>
          <template v-slot:item.fecha_entrega="{ item }">
            {{ parseDate(item.fecha_entrega) }}
          </template>
          <template v-slot:item.fecha_alta_comercializadora="{ item }">
            {{ parseDate(item.fecha_alta_comercializadora) }}
          </template>
          <template v-slot:item.TOTAL_COMPRA="{ item }">
            {{ item.TOTAL_COMPRA | eur }}
          </template>
          <template v-slot:item.verificado="{ item }">
            <v-icon v-if="item.verificado" color="success">mdi-phone</v-icon>
            <v-icon v-else color="warning">mdi-phone-alert</v-icon>
          </template>
          <template v-slot:item.estado="{ item }">
            <EstadoContrato block :estado="item.estado" />
          </template>
          <template v-slot:item.LLAMADA_VENTA="{ item }">
            <div class="d-flex"><span class="text-center mx-auto">{{ item.LLAMADA_VENTA === null ? '-' :
              item.LLAMADA_VENTA
                ? 'Sí' : 'No' }}</span></div>
          </template>
          <template v-slot:item.TEXTO_LEGAL="{ item }">
            <div class="d-flex"><span class="text-center mx-auto">{{ item.TEXTO_LEGAL === null ? '-' :
              item.TEXTO_LEGAL
                ?
                'Sí' : 'No' }}</span></div>
          </template>
          <template v-slot:item.CERTIFICADO="{ item }">
            <div class="d-flex"><span class="text-center mx-auto">{{ item.CERTIFICADO === null ? '-' :
              item.CERTIFICADO
                ?
                'Sí' : 'No' }}</span></div>
          </template>
          <template v-slot:item.llamada="{ item }">
            <v-menu open-on-hover v-if="item.llamada == 'INCIDENCIA'">
              <template v-slot:activator="{ attrs, on }">
                <v-chip color="orange darken-2" v-bind="attrs" v-on="on" label outlined>{{ item.llamada }}</v-chip>
              </template>
              <v-card max-width="400">
                <v-card-subtitle class="font-weight-bold">{{ item.motivo_incidencia }}</v-card-subtitle>
              </v-card>
            </v-menu>
            <span v-else>{{ item.llamada }}</span>
          </template>
        </app-table>
      </div>
    </v-card>

    <div class="d-flex align-center justify-end">
      <v-subheader>{{ $t('common.cargados', { n: items.length, t: count }) }}</v-subheader>
      <v-tooltip v-if="faltanPorCargar" bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn small @click.stop="getItems" :loading="loading" v-bind="attrs" v-on="on"
            style="width: 40px; min-width: unset" color="primary">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        {{ $t('common.cargar_mas') }}
      </v-tooltip>
    </div>
    <!-- <div class="d-flex justify-center text-center">
      <v-pagination
        v-model="page"
        :length="pageCount"
        total-visible="7"
      ></v-pagination>
    </div> -->

    <v-dialog fullscreen transition="dialog-bottom-transition" :value="detailsId != null"
      @input="(v) => (v === false ? (detailsId = null) : null)">
      <v-toolbar dense color="primary" style="position: sticky; top: 0; z-index: 10;">
        <v-spacer></v-spacer>
        <v-btn @click.stop="detailsId = null" icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <DetalleContrato :key="detailsId" :detailsId="detailsId" />
    </v-dialog>
  </v-container>
</template>

<script>
import { perColumnFilter, parseDate, jsonToCsv, downloadFile } from "@/utils/index.js";

export default {
  components: {
    EstadoContrato: () => import("../components/EstadoContrato.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    DetalleContrato: () => import("./DetalleContrato.vue"),
    AppTable: () => import("@/components/AppTable.vue"),
  },
  props: {
    _detailsId: String | Number,
  },
  data() {
    return {
      items: [],
      headers: [],
      search: null,
      desde: null,
      hasta: null,
      loading: false,
      page: 1,
      pagesize: 1,
      loadsize: 1,
      pageCount: 1,
      detailsId: this._detailsId || null,
      count: 0,
      selected: [],

      exporting: false,
      inlineFilters: {},
    };
  },
  computed: {
    faltanPorCargar() {
      return this.items.length < this.count;
    },
  },
  methods: {
    parseDate,
    async getItems(page) {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `/contratos`,
        params: {
          page: Math.floor(this.items.length / this.loadsize) + 1,
        },
      });

      this.items = [...this.items, ...data.items];
      this.count = data.total_items;
      // this.pageCount = data.pages;
      this.pagesize = data.pagesize;
      this.loadsize = data.loadsize;
      this.loading = false;
    },
    syncScroll(event) {
      const scrollTop = this.$refs.scrollTop;
      // Sincroniza el desplazamiento del scroll superior al inferior
      if (scrollTop) {
        scrollTop.scrollLeft = event.target.scrollLeft;
      }
    },
    async getHeaders() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `/contratos/headers`,
      });

      (this.headers = data.map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        };
      })),
        (this.loading = false);
    },
    async exportarTodos() {
      this.exporting = true
      let filters = this.inlineFilters

      if (this.selected?.length > 0)
        filters['ref'] = this.selected

      try {
        const { data: excel } = await axios({
          method: "POST",
          url: `/contratos/exportacion`,
          data: {
            filters: filters
          },
          responseType: 'blob'
        });
        downloadFile(new File([excel], 'contratos_exportados.xlsx', {}));
      }
      catch {
        this.$root.$emit('snack', 'Ha ocurrido un error en la exportación')
      }
      finally {
        this.exporting = false
      }
    },
  },
  mounted() {
    this.getItems();
    this.getHeaders();
  },
  // watch: {
  //   loadpage(n, o) {
  //     if (n > o) {
  //       // const loaded = Math.ceil(this.items.length / this.loadsize);
  //       // for (let index = loaded; index <= n; index++) {
  //       //   this.getItems(index);
  //       // }
  //       this.getItems();
  //     }
  //   },
  // },
};
</script>

<style>
.table-container {
  position: relative;
}

.scroll-top {
  overflow-x: auto;
  margin-bottom: 10px;
  /* Espacio entre el scroll superior y la tabla */
}

.v-table {
  max-width: 100%;
  overflow-x: auto;
}
</style>
